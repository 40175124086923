import React, { useRef, useEffect } from "react"
import { makeStyles } from "@material-ui/core/styles"
import gsap from "gsap"
import { Power3 } from "gsap/gsap-core"

const useStyles = makeStyles(theme => ({
  menuWrapper: {
    width: "50px",
    height: "50px",
    borderRadius: "100%",
    padding: "5px 5px",
    display: "inline-block",
    position: "relative",
    border: "solid 0.5px #615d5d",
    textAlign: "center",
    opacity: 0,
  },
  menuText: {
    position: "absolute",
    color: "#615d5d",
    fontFamily: '"Muli", sans-serif',
    fontSize: "0.3em",
    bottom: "37%",
    letterSpacing: "0.3em",
    textTransform: "uppercase",
    textAlign: "center",
    mixBlendMode: "difference",
    "&:hover": {
      letterSpacing: "0.4em",
    },
  },
}))

const MenuToggle = () => {
  const classes = useStyles()
  const menuCircle = useRef(null)
  const menuTitle = useRef(null)

  useEffect(() => {
    gsap.to(menuCircle.current, 1, {
      opacity: 1,
      ease: Power3.easeOut,
      delay: 1,
    })
  }, [])

  return (
    <div className={classes.menuWrapper} ref={menuCircle}>
      <div className={classes.menuText} ref={menuTitle}>
        menu
      </div>
    </div>
  )
}

export default MenuToggle
