import React, { useRef, useEffect } from "react"
import { makeStyles } from "@material-ui/core/styles"
import { Link } from "gatsby"
import gsap from "gsap"
import { Power3 } from "gsap/gsap-core"

const useStyles = makeStyles(theme => ({
  logo: {
    background: "none",
    border: "none",
    padding: 0,
    margin: 0,
    opacity: 0,
    // mixBlendMode: "exclusion",
  },
}))

export const Logo = props => {
  const classes = useStyles()
  const logoStyle = useRef(null)

  useEffect(() => {
    gsap.to(logoStyle.current, 2, {
      opacity: 1,
      y: 5,
      ease: Power3.easeOut,
      delay: 1,
    })
  }, [])

  return (
    <Link to="/">
      <div className={classes.logo} ref={logoStyle}>
        <svg
          {...props}
          version="1.1"
          id="logo"
          xmlns="http://www.w3.org/2000/svg"
          x="0px"
          y="0px"
          width={props.width}
          fill={props.fill ? props.fill : "#333333"}
          viewBox="0 0 308.25 131.62"
        >
          <g stroke="none">
            <g>
              <g>
                <path
                  d="M22.02,65.89h5.87V29.7h-5.87v-0.74h11.91l9.79,28.47h0.11l9.79-28.47h11.69v0.74h-5.87v36.19h5.87v0.74H48.27v-0.74
                    h5.87V30.02h-0.11L41.39,66.69L28.8,30.39h-0.11v35.51h5.87v0.74H22.02V65.89z"
                />
                <path
                  d="M82.51,65.89h4.66l14.29-37.89h1.27l14.39,37.89h4.71v0.74h-16.3v-0.74h5.87l-5.55-14.71H93.56l-5.56,14.71h5.87v0.74
                    H82.51V65.89z M99.7,35.04l-5.82,15.4h11.64L99.7,35.04z"
                />
                <path
                  d="M177.01,53.14h-4.23v12.01h-0.63c-0.39-0.39-0.71-0.65-0.95-0.79c-0.25-0.14-0.63-0.21-1.16-0.21
                    c-0.64,0-1.28,0.17-1.93,0.5c-0.65,0.33-1.41,0.71-2.27,1.14c-0.87,0.42-1.88,0.8-3.04,1.14c-1.16,0.33-2.57,0.5-4.23,0.5
                    c-2.5,0-4.73-0.42-6.67-1.24c-1.94-0.83-3.81-2.14-5.61-3.94c-1.76-1.76-3.23-3.84-4.39-6.24c-1.17-2.4-1.75-5.1-1.75-8.1
                    c0-2.01,0.23-3.82,0.69-5.42c0.46-1.6,1.01-3.01,1.67-4.21c0.65-1.2,1.32-2.2,2.01-3.02c0.69-0.81,1.26-1.45,1.72-1.9
                    c1.59-1.59,3.4-2.88,5.45-3.86c2.05-0.99,4.37-1.48,6.98-1.48c1.34,0,2.44,0.11,3.31,0.32c0.86,0.21,1.62,0.45,2.25,0.71
                    c0.63,0.26,1.21,0.5,1.72,0.71c0.51,0.21,1.08,0.32,1.72,0.32c0.71,0,1.21-0.1,1.51-0.29c0.3-0.19,0.59-0.47,0.87-0.82h0.79
                    v10.58h-0.58c-0.14-0.92-0.42-1.99-0.82-3.23c-0.41-1.23-1.04-2.42-1.9-3.54c-0.87-1.13-2.01-2.08-3.44-2.86
                    c-1.43-0.77-3.26-1.16-5.48-1.16c-2.65,0-4.77,0.56-6.38,1.67c-1.61,1.11-2.86,2.58-3.76,4.39c-0.9,1.82-1.5,3.87-1.8,6.16
                    c-0.3,2.29-0.45,4.6-0.45,6.93c0,1.59,0.07,3.18,0.21,4.76c0.14,1.59,0.38,3.09,0.71,4.5c0.34,1.41,0.8,2.72,1.4,3.92
                    c0.6,1.2,1.38,2.22,2.33,3.07c1.06,0.95,2.15,1.61,3.28,1.98c1.13,0.37,2.59,0.56,4.39,0.56c1.09,0,2.19-0.18,3.28-0.56
                    c1.09-0.37,2.07-0.93,2.94-1.67c0.86-0.74,1.57-1.68,2.12-2.83c0.55-1.15,0.82-2.5,0.82-4.05v-4.45h-6.83V52.4h16.14V53.14z"
                />
                <path
                  d="M194,65.89h4.66l14.29-37.89h1.27l14.39,37.89h4.71v0.74h-16.3v-0.74h5.87l-5.56-14.71h-12.27l-5.56,14.71h5.88v0.74H194
                    V65.89z M211.19,35.04l-5.82,15.4h11.64L211.19,35.04z"
                />
                <path
                  d="M250.24,65.89h5.87V29.7h-5.87v-0.74h11.32l17.88,28.84h0.11V29.7h-5.88v-0.74h12.54v0.74h-5.87v37.67h-1.06
                    l-22.28-35.51h-0.11v34.02h5.87v0.74h-12.54V65.89z"
                />
              </g>
              <g>
                <rect x="21.68" y="97.17" width="42.21" height="0.8" />
                <rect x="244.36" y="97.17" width="42.21" height="0.8" />
              </g>
            </g>
            <g>
              <path
                d="M81.41,103.18h-1.9V88.66h4.05c1.54,0,2.63,0.16,3.26,0.47c0.64,0.32,1.12,0.78,1.44,1.38c0.32,0.61,0.48,1.31,0.48,2.12
                c0,1.36-0.46,2.5-1.39,3.43c-0.93,0.92-2.31,1.38-4.15,1.38h-1.79V103.18z M81.41,96h1.27c1.68,0,2.78-0.32,3.31-0.97
                c0.53-0.65,0.79-1.42,0.79-2.31c0-0.78-0.21-1.41-0.64-1.89c-0.43-0.47-1.27-0.71-2.54-0.71h-2.19V96z"
              />
              <path
                d="M105.71,103.18h-1.9V88.66h4.5c1.11,0,1.94,0.16,2.49,0.47s0.99,0.78,1.32,1.38c0.33,0.61,0.49,1.26,0.49,1.96
                c0,0.56-0.11,1.11-0.33,1.63c-0.22,0.52-0.53,1-0.92,1.42s-1.01,0.8-1.83,1.12c0.31,0.18,0.54,0.34,0.7,0.5s0.35,0.41,0.57,0.76
                c0.22,0.35,0.42,0.67,0.59,0.98l2.31,4.3h-2.16l-2-3.82c-0.49-0.92-1.12-1.73-1.9-2.43h-1.91V103.18z M105.71,95.48h1.85
                c0.77,0,1.36-0.12,1.77-0.36c0.4-0.24,0.73-0.59,0.96-1.05c0.24-0.46,0.36-0.95,0.36-1.47c0-0.46-0.09-0.87-0.28-1.23
                c-0.19-0.36-0.46-0.66-0.82-0.9c-0.36-0.24-0.98-0.36-1.85-0.36h-1.98V95.48z"
              />
              <path
                d="M134.22,103.62c-1.82,0-3.3-0.67-4.45-2c-1.15-1.34-1.72-3.23-1.72-5.7s0.57-4.36,1.72-5.7c1.15-1.34,2.63-2,4.45-2
                s3.3,0.67,4.46,2s1.73,3.23,1.73,5.7s-0.58,4.36-1.73,5.7S136.04,103.62,134.22,103.62z M134.22,102.17
                c1.32,0,2.35-0.57,3.09-1.71c0.74-1.14,1.11-2.65,1.11-4.54s-0.37-3.39-1.11-4.54c-0.74-1.14-1.77-1.71-3.09-1.71
                s-2.35,0.57-3.1,1.71S130,94.04,130,95.92s0.37,3.39,1.12,4.54S132.9,102.17,134.22,102.17z"
              />
              <path
                d="M154.77,100.72h1.89v0.09c0,0.26,0.07,0.5,0.22,0.71c0.15,0.21,0.37,0.37,0.67,0.48c0.3,0.11,0.64,0.16,1.01,0.16
                c0.39,0,0.75-0.09,1.08-0.27c0.33-0.18,0.58-0.46,0.72-0.82s0.22-1.19,0.22-2.45v-9.97h1.87v10.01c0,1.58-0.17,2.67-0.51,3.25
                c-0.34,0.58-0.76,1.02-1.26,1.29c-0.5,0.28-1.18,0.42-2.04,0.42c-1.42,0-2.42-0.29-3-0.88c-0.58-0.58-0.88-1.23-0.88-1.93V100.72z
                "
              />
              <path d="M178.33,103.18V88.66h8.21v1.59h-6.31v4.59h5.51v1.45h-5.51v5.3h6.82v1.6H178.33z" />
              <path
                d="M211.13,100.06h1.74v0.09c0,0.57-0.2,1.15-0.59,1.75c-0.4,0.59-0.93,1.03-1.61,1.3c-0.68,0.28-1.48,0.42-2.4,0.42
                c-1.85,0-3.36-0.64-4.53-1.93c-1.17-1.28-1.76-3.21-1.76-5.77c0-2.55,0.58-4.47,1.75-5.76s2.68-1.94,4.55-1.94
                c0.91,0,1.71,0.14,2.39,0.42c0.68,0.28,1.21,0.72,1.61,1.32c0.4,0.6,0.59,1.18,0.59,1.73v0.09h-1.74v-0.09
                c0-0.35-0.11-0.69-0.34-1.02c-0.22-0.33-0.54-0.58-0.96-0.75s-0.91-0.25-1.49-0.25c-1.49,0-2.59,0.59-3.32,1.76
                c-0.73,1.17-1.09,2.67-1.09,4.49c0,1.84,0.37,3.34,1.1,4.5c0.73,1.16,1.83,1.75,3.3,1.75c1.01,0,1.73-0.21,2.16-0.63
                c0.43-0.42,0.64-0.88,0.64-1.39V100.06z"
              />
              <path d="M231.96,103.18V90.12h-4.94v-1.46h11.69v1.46h-4.87v13.06H231.96z" />
            </g>
          </g>
        </svg>
      </div>
    </Link>
  )
}
