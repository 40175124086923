import { createMuiTheme } from "@material-ui/core/styles"

const theme = createMuiTheme({
  palette: {
    common: {
      black: "#393939",
      white: "#fff",
    },
    primary: {
      main: "rgba(154, 133, 106, 1)",
    },
    secondary: {
      main: "#3b5280",
      //   light: "#88badf",
      //   dark: "2E4066",
      contrastText: "#fff",
    },
    background: {
      default: "#fff",
      white: "#fff",
    },
  },
  text: {
    primary: "#393939",
  },
  action: {
    active: "rgba(0, 0, 0, 0)",
    hover: "rgba(0, 0, 0, 0)",
    hoverOpacity: 0,
    selected: "rgba(0, 0, 0, 0)",
    selectedOpacity: 0,
    focus: "rgba(0, 0, 0, 0)",
    focusOpacity: 0,
    activatedOpacity: 0,
  },
  divider: "rgba(255, 255, 255, 0.9)",
  typography: {
    fontSize: 11, //default is 14
    htmlFontSize: 10,
    fontFamily: [
      '"Noto Sans KR", sans-serif',
      '"Nanum Myeongjo", serif',
      '"Karla", sans-serif',
      '"Muli", sans-serif',
    ].join(","),
    h3: {
      fontSize: "3rem",
      lineHeight: 1.167,
      fontFamily: '"Muli", sans-serif',
      fontWeight: 600,
      letterSpacing: "0.4em",
    },
    h4: {
      fontSize: "2rem",
      lineHeight: 1.167,
      fontFamily: '"Muli", sans-serif',
      fontWeight: 600,
      letterSpacing: "0.3em",
    },
    h5: {
      fontSize: "0.9rem",
      lineHeight: 0,
      fontFamily: '"Muli", sans-serif',
      fontWeight: 400,
      letterSpacing: "0.8em",
    },
    body: {
      fontSize: "1rem",
      fontFamily: '"Noto Sans KR", sans-serif',
      lineHeight: 1.2,
    },
  },
  status: {
    danger: "orange",
  },

  //   overrides: {
  //     MuiCssBaseline: {
  //       "@global": {
  //         "@font-face": [NanumGothicCoding],
  //       },
  //     },
  //   },
})

export default theme
