import React, { useState } from "react"
import { Link } from "gatsby"
import {
  AppBar,
  Toolbar,
  IconButton,
  Drawer,
  List,
  ListItem,
  Divider,
} from "@material-ui/core"
import { makeStyles } from "@material-ui/core/styles"
import DragHandleOutlinedIcon from "@material-ui/icons/DragHandleOutlined"
import { Logo } from "./logo"
import "../styles/component.css"
import MenuToggle from "./menuToggle"

const useStyles = makeStyles(theme => ({
  mobileBar: {
    color: "rgba(255, 255, 255, 0.7)",
    backgroundColor: "transparent",
  },
  toolbarLayout: {
    justifyContent: "space-between",
  },
  menuButton: {
    width: "15%",
    top: "5px",
    padding: "10px 0px",
    borderRadius: 0,
    "&:hover": {
      backgroundColor: "rgba(0, 0, 0, 0)",
    },
    [theme.breakpoints.down("sm")]: {
      width: "25%",
    },
  },
  menuIcon: {
    width: "1em",
    [theme.breakpoints.up("md")]: {
      fontSize: "1.2em",
      "&:hover": {
        fontSize: "1.3em",
      },
    },
  },
  paper: {
    backgroundColor: "rgba(255, 255, 255, 1)",
    marginTop: "10%",
    height: "90%",
    [theme.breakpoints.up("md")]: {
      marginTop: "5%",
    },
  },
  logoWrapper: {
    margin: "30px",
  },
  logoWrapperCenter: {
    marginTop: "23px",
  },
  empty: {
    width: "15%",
    [theme.breakpoints.down("sm")]: {
      width: "25%",
    },
  },
  listItem: {
    "&:hover": {
      backgroundColor: "rgba(0, 0, 0, 0)",
    },
  },
  itemText: {
    padding: "0px 20px",
    color: "#615e5e",
    margin: "0px",
    fontSize: "0.7em",
    fontWeight: 500,
    textDecoration: "none",
    textTransform: "uppercase",
    letterSpacing: "0.3em",
    "&:hover": {
      color: "#3b5280",
      fontWeight: 700,
      transition: "color 1s ease",
    },
    "&:active": {
      color: "#3b5280",
      fontWeight: 700,
    },
  },
  snsText: {
    padding: "0px 20px",
    color: "#615e5e",
    margin: "0px",
    fontSize: "0.6em",
    fontWeight: 500,
    textTransform: "uppercase",
    letterSpacing: "0.3em",
    textDecoration: "none",
    "&:hover": {
      color: "#3b5280",
    },
  },
  ahref: {
    textDecoration: "none",
  },
  hr: {
    backgroundColor: "rgba(0, 0, 0, 0.05)",
    width: "75%",
    marginLeft: "10%",
    marginTop: "40%",
  },
}))

const MobileToolbar = ({ onMenuClick }) => {
  const classes = useStyles()
  // const [isMobile, setMobile] = useState(global.window.innerWidth < 800)

  // const updateMedia = () => {
  //   setMobile(global.window.innerWidth < 800)
  // }

  // useEffect(() => {
  //   global.window.addEventListener("resize", updateMedia)
  //   return () => global.window.removeEventListener("resize", updateMedia)
  // }, [])

  return (
    <>
      <AppBar position="fixed" className={classes.mobileBar} elevation={0}>
        <Toolbar className={classes.toolbarLayout}>
          <IconButton
            edge="start"
            className={classes.menuButton}
            color="primary"
            aria-label="menu"
            onClick={onMenuClick}
            disableRipple={true}
          >
            <MenuToggle />
          </IconButton>
          {/* <Typography variant="h6" color="inherit">
            {title}
          </Typography> */}
          <div className={classes.logoWrapperCenter}>
            <Logo width={"150px"} />
          </div>
          <div className={classes.empty}></div>
        </Toolbar>
      </AppBar>
    </>
  )
}

const MobileDrawer = ({ open, onClose, onItemClick }) => {
  const classes = useStyles()
  return (
    <Drawer open={open} onClose={onClose} classes={{ paper: classes.paper }}>
      <List>
        <div className={classes.logoWrapper}>
          <Logo width={"150px"} />
        </div>
        <ListItem
          button
          component={Link}
          to="/"
          onClick={onItemClick("Home")}
          activeStyle={{ color: "#3b5280" }}
          className={classes.listItem}
          disableRipple={true}
        >
          <h5 className={classes.itemText}>Home</h5>
        </ListItem>
        <ListItem
          button
          component={Link}
          to="/about"
          onClick={onItemClick("About")}
          activeStyle={{ color: "#3b5280" }}
          className={classes.listItem}
          disableRipple={true}
        >
          <h5 className={classes.itemText}>About</h5>
        </ListItem>
        <ListItem
          button
          component={Link}
          to="/portfolio"
          onClick={onItemClick("Portfolio")}
          activeStyle={{ color: "#3b5280" }}
          className={classes.listItem}
          disableRipple={true}
        >
          <h5 className={classes.itemText}>Portfolio</h5>
        </ListItem>
        <ListItem
          button
          component={Link}
          to="/gallery"
          onClick={onItemClick("Gallery")}
          activeStyle={{ color: "#3b5280" }}
          className={classes.listItem}
          disableRipple={true}
        >
          <h5 className={classes.itemText}>gallery</h5>
        </ListItem>
        <ListItem
          button
          component={Link}
          to="/contact"
          onClick={onItemClick("Reservation")}
          activeStyle={{ color: "#3b5280" }}
          className={classes.listItem}
          disableRipple={true}
        >
          <h5 className={classes.itemText}>Contact</h5>
        </ListItem>
      </List>
      <Divider className={classes.hr} />
      <List>
        <ListItem>
          <a
            href="https://www.instagram.com/magan_project/"
            className={classes.ahref}
            target="_blank"
            rel="noopener noreferrer"
          >
            <h5 className={classes.snsText}>Instagram</h5>
          </a>
        </ListItem>
        <ListItem>
          <a
            href="https://blog.naver.com/maganproject/"
            className={classes.ahref}
            target="_blank"
            rel="noopener noreferrer"
          >
            <h5 className={classes.snsText}>Blog</h5>
          </a>
        </ListItem>
      </List>
    </Drawer>
  )
}

const MobileMenuBar = () => {
  const [drawer, setDrawer] = useState(false)
  const [title, setTitle] = useState("Home")

  const toggleDrawer = () => {
    setDrawer(!drawer)
  }

  const onItemClick = title => () => {
    setTitle(title)
    setDrawer(!drawer)
  }

  return (
    <>
      <MobileToolbar title={title} onMenuClick={toggleDrawer} />
      <MobileDrawer
        open={drawer}
        onClose={toggleDrawer}
        onItemClick={onItemClick}
      />
    </>
  )
}

export default MobileMenuBar
